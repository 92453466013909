import React from 'react'
import { NavB } from './components/NavB'
import AudioSpectrum from 'react-audio-spectrum';
import { useResponsive } from './hooks/useResponsive';
import { ImgComponent } from './components/ImgComponent';
import { AudioSpectrumComponent } from './components/AudioSpectrumComponent';
import { CopyrightComponent } from './components/CopyrightComponent';

export const RBB = () => {

  const [ respWidth ] = useResponsive();

  const gap = ( respWidth > 600 ) ? 6 : 4

  return (
    <>
      <NavB />

      <ImgComponent />

      <AudioSpectrumComponent />

      <CopyrightComponent />

      <AudioSpectrum
        id="audio-canvas"
        height={100}
        audioId={'audio-element'}
        width={'auto'}
        capColor={'red'}
        capHeight={2}
        meterWidth={1}
        meterCount={512}
        meterColor={[
          {stop: 0, color: '#f00'},
          {stop: 0.5, color: '#0CD7FD'},
          {stop: 1, color: 'red'}
        ]}
        gap={gap}
      />
    </>
  )
}
