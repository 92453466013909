import React from 'react'
import { Container, Navbar, Nav, NavLink } from 'react-bootstrap'
import facebook from '../media/facebook.png'
import youtube from '../media/youtube.png'
import { useResponsive } from '../hooks/useResponsive'

export const NavB = () => {
  
  const RedirectToFb = () => {
    window.open('https://www.facebook.com/radiobonaobendicio/')
  }

  const RedirectToYT = () => {
    window.open('https://www.youtube.com/c/RadioBonaoBendición')
  }

  const [ respWidth ] = useResponsive();

  return (
  <Navbar id='navBakcground' bg = 'transparent' variant="dark">
    <Container style={{ display: 'flex', justifyContent: ( respWidth <= 370 ) ? 'center' : 'space-between' }}>
      <Navbar.Brand style = {{cursor: 'pointer', margin: 0, padding: 0}}>
        <span className = 'Navb-tittle'>
          <NavLink id='tituloNav' style = {{textDecoration: 'none', color: 'white', margin: 0, padding: 0}} to = '/Dashboard'>Radio Bonao Bendición</NavLink>
        </span>
      </Navbar.Brand>

      {
        ( respWidth > 350 )
          &&
        <Nav className="ml-auto">
          <img onClick={ RedirectToFb } className='mr-1' src={ facebook } style={{ borderRadius: '50%', width: '40px', cursor: 'pointer' }} alt="" />
          <img onClick={ RedirectToYT } src={ youtube } style={{ borderRadius: '50%', width: '40px', cursor: 'pointer' }} alt="" />
        </Nav>
      }

    </Container>
  </Navbar>
  )
}
