import React from 'react'
import imag from '../media/img23.svg'
import { useResponsive } from '../hooks/useResponsive'

export const ImgComponent = () => {

  const [ respWidth ] = useResponsive();

  return (
    <img src={ imag } style={{  
      width: 'auto',
      ...handleHeight(respWidth),
      objectFit: 'cover',
      top: '25%',
      position: 'absolute',
      right: '50%',
      transform: 'translateX(50%)'
    }} alt="" />
  )
}

const handleHeight = ( respWidth ) => {
  if ( respWidth >= 400 ) {
    return { height: '180px',  }
  } else if ( respWidth < 400 && respWidth > 369 ) {
    return { height: '150px',  }
  } else {
    return { height: '100px',  }
  }
}
