import React from 'react'
import { useResponsive } from '../hooks/useResponsive'

export const CopyrightComponent = () => {

    const [ respWidth ] = useResponsive();

    const getFontSize = handleFontSize( respWidth )

    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        zIndex: -1,
        position: 'fixed',
        top: '50%',
        right: 0,
        transform: 'rotate(-90deg) translate(53.5%)',
        transformOrigin: 'center right',
        ...getFontSize
    };

  return (
    <div style={style}>
        <p>Copyright ©{new Date().getFullYear()} All rights reserved by: Radio Bonao Bendicion 800 am</p>
    </div>
  );
};

const handleFontSize  = (respWidth) => {

    if ( respWidth <= 350 ) {
        return { fontSize: '7.8px' };
    } else if ( respWidth > 350 && respWidth <= 400 ) {
        return { fontSize: '9px' };
    } else if ( respWidth > 400 && respWidth <= 600 ) {
        return { fontSize: '10px' };
    } else if ( respWidth > 600 && respWidth <= 991 ) {
        return { fontSize: '12.5px' };
    } else if (respWidth > 991 ) {
        return { fontSize: undefined };
    } else {
        return { fontSize: undefined };
    }
}