import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export const AudioSpectrumComponent = () => {
    const RadioBonaoBendicion = 'https://radio3.domint.net:8098/stream'

    const [isPlaying, setIsPlaying] = useState(false)

    const handlePlay = () => {
        setIsPlaying(true)
        document.getElementById('audio-element').play()
    }

    const handleStop = () => {
        setIsPlaying(false)
        document.getElementById('audio-element').pause()
    }

    useEffect(() => {
        const checkAudioLink = async () => {
        
          try {
            await axios.head(RadioBonaoBendicion, { timeout: 10000 });            
          } catch (error) {
            handleStop()
          }
        };
    
        checkAudioLink()
     
    }, [RadioBonaoBendicion, isPlaying]);

    return (
      <div style={{ position: 'absolute', right: '50%', top: '60%', transform: 'translate(50%)', zIndex: 1 }}>
        {
            ( isPlaying )
                ?
            <i id='play-button' onClick={ handleStop } className="bi bi-pause-circle" style={{ fontSize: '60px', zIndex: 9999, cursor: 'pointer', padding: 0, margin: 0 }}></i>
                :
            <i id='play-button' onClick={ handlePlay } className="bi bi-play-circle" style={{ fontSize: '60px', zIndex: 9999, cursor: 'pointer', padding: 0, margin: 0 }}></i>
        }
        <audio crossOrigin='anonymous' hidden controls id="audio-element">
            <source src={ RadioBonaoBendicion } type="audio/mpeg" />
        </audio>
      </div>
    );
}
